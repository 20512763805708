@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: "New Balance";
	src: url("fonts/NewBalance.ttf") format("truetype");
	/* Add more font properties if needed */
}

* {
	-webkit-tap-highlight-color: transparent;
	-moz-tap-highlight-color: transparent;
	tap-highlight-color: transparent;
}

/* Match height to the viewport variable set by javascript */
.h-viewport {
	min-height: var(--window-height);
}

html {
	letter-spacing: -0.03em;
	background: url(./img/nb-texture.svg) top center no-repeat;
	background-size: cover;
	background-color: #151415;

	color: #fff;
}

.Icon path {
	stroke: currentColor !important;
}

.Svg {
	@apply relative;
}

.Svg svg {
	width: 100%;
	height: auto;
}

input {
	@apply placeholder-[#FFF] placeholder-opacity-50;
	background: #000;

	@apply transition opacity-50;
}

input:focus {
	@apply opacity-100;
	outline: none;
	border: none;
}

button[disabled] {
	opacity: 0.2;
	cursor: not-allowed;
}

h1 {
	@apply text-[50px] md:text-[70px];
	line-height: 100%;
}

h2 {
	@apply text-[30px] md:text-[50px];
	line-height: 100%;
}

hr {
	@apply opacity-20;
}
